import React from 'react';
import {Button, Card, DatePicker, Form, Input, Select, Switch} from "antd";
import {useForm} from "antd/es/form/Form";
import axios from "axios";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
const selectBefore = (
    <Form.Item name="title" noStyle initialValue={"Mr"}>
        <Select
            popupMatchSelectWidth={true}
            options={[
                {label: "Mr", value: "Mr"},
                {label: "Mrs", value: "Mrs"},
                {label: "Miss", value: "Miss"},
            ]}
        />
    </Form.Item>
);

function App() {
    const [form] = useForm();
    const [loading, setLoading] = React.useState(false);
    const [roles, setRoles] = React.useState([]);
    const [isRelieving, setRelieving] = React.useState(false)

    React.useEffect(() => {
        fetch('https://backend.offer.webologixglobal.com/roles').then((response) => {
            response.json().then((data) => {
                setRoles(data.roles);
            });
        })
    }, [])

    const createOffer = (values) => {
        setLoading(true);
        const url = "https://backend.offer.webologixglobal.com/offer-letter";
        axios({
            method: 'post',
            url: url,
            data: {
                ...values,
                name: `${values.title} ${values.name}`,
                doj: values.doj.format("YYYY-MM-DD"),
                dog: values.dog.format("YYYY-MM-DD"),
            }
        })
            .then((response) => {
                window.open(`http://offer.webologixglobal.com/pdf/${values.title} ${values.name}.pdf`, '_blank')
            })
            .catch((e) => {
                console.log(e)
                alert("Something went wrong")
            })
            .finally(() => setLoading(false));
    }

    const createRelieving = (values) => {
        setLoading(true);
        const url = "https://backend.offer.webologixglobal.com/relieving-letter";
        axios({
            method: 'post',
            url: url,
            data: {
                ...values,
                doj: values.doj.format("YYYY-MM-DD"),
                dol: values.dol.format("YYYY-MM-DD"),
                dog: values.dog.format("YYYY-MM-DD"),
            }
        })
            .then((response) => {
                window.open(`http://offer.webologixglobal.com/pdf/${values.name}_relieving.pdf`, '_blank')
            })
            .catch((e) => {
                console.log(e)
                alert("Something went wrong")
            })
            .finally(() => setLoading(false));
    }

    const onFinish = (values) => {
        if (isRelieving)
            createRelieving(values)
        else
            createOffer(values)
    }


    return (
        <Card title={"Create offer letter"} className={"p-2"}>
            <div className={'flex gap-3'}>
                <Switch value={isRelieving} onChange={(val) => setRelieving(val)} className={'mb-4'}/>
                <span>Relieving Letter</span>
            </div>
            <Form form={form} layout={"vertical"} onFinish={onFinish} initialValues={{
                dog: dayjs().tz('US/Eastern'),
            }}>
                <Form.Item
                    label={"Full Name"}
                    name={"name"}
                    rules={[{required: true, message: 'Please input your name!'}]}
                >
                    <Input addonBefore={selectBefore} type="text" className={"form-control"}/>
                </Form.Item>
                <Form.Item
                    label={"Date of Generation"}
                    name={"dog"}
                    rules={[{required: true, message: 'Please input date of generation!'}]}
                >
                    <DatePicker/>
                </Form.Item>
                <Form.Item
                    label={"Date of Joining"}
                    name={"doj"}
                    rules={[{required: true, message: 'Please input date of joining!'}]}
                >
                    <DatePicker/>
                </Form.Item>
                <Form.Item
                    label={"Remote"}
                    name={"remote"}
                >
                    <Switch/>
                </Form.Item>
                {
                    isRelieving &&
                    <Form.Item
                        label={"Date of Relieving"}
                        name={"dol"}
                        rules={[{required: true, message: 'Please input date of relieving!'}]}
                    >
                        <DatePicker/>
                    </Form.Item>
                }
                <Form.Item
                    label={"Role"}
                    name={"role"}
                    rules={[{required: true, message: 'Please input your role!'}]}
                    initialValue={"data-analyst"}
                >
                    <Select
                        options={roles}
                    />
                </Form.Item>
            </Form>
            <Button loading={loading} disabled={loading} onClick={() => form.submit()} type={"primary"}>Submit</Button>
        </Card>
    );
}

export default App;
